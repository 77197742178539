<template>
    <front-layout>
        <div class="intro wrapper">
            <h1 class="intro__title text-style">supported formats</h1>

            <v-expansion-panels focusable style="width: 50%; margin: 0 auto;">
                <v-expansion-panel v-for="(item, header) in types" :key="header">
                    <v-expansion-panel-header v-text="header"/>
                    <v-expansion-panel-content>
                        <v-chip v-for="element in Object.keys(item)"
                                :key="element"
                                v-text="element"
                                class="ma-1"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import Formats from "../../api/Formats";

    export default {
        name: "supported-formats",
        components: {FrontLayout},
        data: function () {
            return {
                types: []
            }
        },
        mounted() {
          this.fetchFormats()
        },
        methods: {
            async fetchFormats() {
                this.types = await Formats.fetchList()
            }
        },
    }
</script>

<style scoped>

</style>
